// Chakra imports
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
// Custom components
// Assets
import { Field, Form, Formik, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { BsFillFlagFill } from 'react-icons/bs';
import { FaAddressCard, FaCity } from 'react-icons/fa';
import { IoLocation } from 'react-icons/io5';
import { CreateBusinessStep3 } from 'shared/types/app.types';

export default function CreateBusinessStep3Form({
  nextStep3,
  prevStep,
  step3,
}: {
  nextStep3: (values: CreateBusinessStep3) => void;
  prevStep: () => void;
  step3: CreateBusinessStep3;
}) {
  const textColorSecondary = 'brand.400';
  const { t } = useTranslation();
  const submitStep1 = (values: CreateBusinessStep3) => {
    nextStep3(values);
  };

  const isValidZipCode = (zip: string): string => {
    const zipCodeRegex = /^\d{7}$/;
    const validZip = zipCodeRegex.test(zip);
    if (!zip) {
      return t('Please add ZIP Code');
    }
    if (!validZip) {
      return t('Please enter a valid ZIP Code');
    }
    return '';
  };

  const disableCreateButton = (values: CreateBusinessStep3, errors: FormikErrors<CreateBusinessStep3>) => {
    return Object.keys(errors).length > 0;
  };

  return (
    <>
      <Formik
        initialValues={{
          address: {
            street: step3.address?.street ?? '',
            city: step3.address?.city ?? '',
            state: step3.address?.state ?? '',
            country: step3.address?.country ?? '',
            longitude: 0,
            latitude: 0,
          },
        }}
        onSubmit={submitStep1}
      >
        {(props) => (
          <Form>
            <Field name="address.country" validate={(value: string) => (!value ? t('Please add Country') : '')}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="10px" isRequired={true} isInvalid={form.errors.address?.country && form.touched.address?.country}>
                  <FormLabel htmlFor="address.country">{t('Country')}</FormLabel>
                  <InputGroup size="md">
                    <Input
                      {...field}
                      id="address.country"
                      variant={'auth'}
                      fontSize="md"
                      type="text"
                      placeholder={t('Country')}
                      fontWeight="500"
                      size="md"
                    />
                    <InputLeftElement display="flex" alignItems="center">
                      <Icon color={textColorSecondary} as={BsFillFlagFill} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.address?.country}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="address.city" validate={(value: string) => (!value ? t('Please add City') : '')}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="10px" isRequired={true} isInvalid={form.errors.address?.city && form.touched.address?.city}>
                  <FormLabel htmlFor="address.city">{t('City')}</FormLabel>
                  <InputGroup size="md">
                    <Input
                      {...field}
                      id="address.city"
                      variant={'auth'}
                      fontSize="md"
                      type="text"
                      placeholder={t('City')}
                      fontWeight="500"
                      size="md"
                    />
                    <InputLeftElement display="flex" alignItems="center">
                      <Icon color={textColorSecondary} as={FaCity} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.address?.city}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            {props.values.address.country === 'USA' && (
              <Field name="address.state">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl mb="10px">
                    <FormLabel htmlFor="address.state">{t('State (Optional)')}</FormLabel>
                    <InputGroup size="md">
                      <Input
                        {...field}
                        id="address.state"
                        variant={'auth'}
                        fontSize="md"
                        type="text"
                        placeholder={t('Optional')}
                        fontWeight="500"
                        size="md"
                      />
                      <InputLeftElement display="flex" alignItems="center">
                        <Icon color={textColorSecondary} as={FaAddressCard} />
                      </InputLeftElement>
                    </InputGroup>
                    <FormErrorMessage>{form.errors.address?.state}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}
            <Field name="address.street" validate={(value: string) => (!value ? t('Please add Street Address') : '')}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="10px" isRequired={true} isInvalid={form.errors.address?.street && form.touched.address?.street}>
                  <FormLabel htmlFor="address.street">{t('Street Address & number')}</FormLabel>
                  <InputGroup size="md">
                    <Input
                      {...field}
                      id="address.street"
                      variant={'auth'}
                      fontSize="md"
                      type="text"
                      placeholder={t('Street Address')}
                      fontWeight="500"
                      size="md"
                    />
                    <InputLeftElement display="flex" alignItems="center">
                      <Icon color={textColorSecondary} as={IoLocation} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.address?.street}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="address.zipCode">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="10px">
                  <FormLabel htmlFor="address.zipCode">{`${t('ZIP Code')} (${t('not required')})`}</FormLabel>
                  <InputGroup size="md">
                    <Input
                      {...field}
                      id="address.zipCode"
                      variant={'auth'}
                      fontSize="md"
                      type="text"
                      placeholder={t('ZIP Code')}
                      fontWeight="500"
                      size="md"
                    />
                  </InputGroup>
                  <FormErrorMessage>{form.errors.address?.zipCode}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Flex pt="10px" gap="5px" justifyContent={'end'}>
              <Button variant="action" fontSize="md" fontWeight="500" px="24px" onClick={prevStep} py="5px">
                {t('Back')}
              </Button>
              <Button
                variant="brand"
                fontSize="md"
                type="submit"
                fontWeight="500"
                isDisabled={disableCreateButton(props.values, props.errors)}
                px="24px"
                py="5px"
              >
                {t('Next')}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}
