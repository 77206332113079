// Chakra imports
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Icon, Input, InputGroup, InputLeftElement, Select } from '@chakra-ui/react';
// Custom components
// Assets
import { Field, Form, Formik, FormikErrors } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown, MdPalette } from 'react-icons/md';
import { BusinessTypeEnum } from 'shared/enums';
import { BusinessType, CreateBusinessStep4 } from 'shared/types/app.types';

export default function CreateBusinessStep4Form({
  nextStep4,
  prevStep,
  step4,
}: {
  nextStep4: (values: CreateBusinessStep4) => void;
  prevStep: () => void;
  step4: CreateBusinessStep4;
}) {
  const [businessType, setBusinessType] = useState<BusinessType>(BusinessTypeEnum.BARBER_SHOP);
  const { t } = useTranslation();
  const businessTypes: string[] = Object.values(BusinessTypeEnum);
  const textColorSecondary = 'brand.400';

  const submitStep4 = (values: CreateBusinessStep4) => {
    nextStep4({ ...values, businessType });
  };

  const disableCreateButton = (errors: FormikErrors<CreateBusinessStep4>) => {
    return !!Object.keys(errors).length;
  };

  return (
    <>
      <Formik
        initialValues={{
          color: step4.color ?? '',
          businessType: step4.businessType ?? BusinessTypeEnum.BEAUTY_SALON,
        }}
        onSubmit={submitStep4}
      >
        {(props) => (
          <Form>
            <Field name="color" key="color">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="10px" isRequired={true} isInvalid={form.errors.color && form.touched.color}>
                  <FormLabel htmlFor="color">{t('Business Color')}</FormLabel>

                  <InputGroup size="md">
                    <Input
                      {...field}
                      id="color"
                      type="color"
                      variant="auth"
                      fontSize="md"
                      ms={{ base: '0px', md: '0px' }}
                      fontWeight="500"
                      size="lg"
                    />
                    <InputLeftElement display="flex" alignItems="center">
                      <Icon color={textColorSecondary} as={MdPalette} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.color}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="businessType">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="20px" isInvalid={form.errors.businessType && form.touched.businessType} isRequired={true}>
                  <FormLabel htmlFor="businessType">{t('Business Type')}</FormLabel>
                  <InputGroup size="md" key="businessType">
                    <Select
                      {...field}
                      variant="auth"
                      onChange={(val) => setBusinessType(val.target.value as BusinessType)}
                      placeholder={t('Select your business type')}
                      iconColor="transparent"
                      value={businessType}
                    >
                      {businessTypes.map((businessType) => {
                        return (
                          <option key={businessType} value={`${businessType}`}>
                            {t(businessType)}
                          </option>
                        );
                      })}
                    </Select>
                    <InputLeftElement display="flex" alignItems="center">
                      <Icon color={textColorSecondary} as={MdKeyboardArrowDown} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.businessType}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex pt="10px" gap={2} justifyContent={'end'}>
              <Button variant="action" fontSize="md" fontWeight="500" px="24px" onClick={prevStep} py="5px">
                {t('Back')}
              </Button>
              <Button variant="brand" fontSize="md" type="submit" fontWeight="500" isDisabled={disableCreateButton(props.errors)} px="24px" py="5px">
                {t('Next')}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}
