import axios from 'axios';
import axiosConfig from 'shared/axiosConfig';
import { BlockCustomerDTO, CreateBusinessDTO, UpdateBusinessDTO } from 'shared/types/app.types';

export async function createBusiness({ businessDataFromForm }: { businessDataFromForm: CreateBusinessDTO; nextStep: () => void }) {
  const result = await axiosConfig.post('/businesses', businessDataFromForm);
  return result.data;
}

export async function blockUnblockCustomer({ businessPayload, businessId }: { businessPayload: BlockCustomerDTO; businessId: string }) {
  const result = await axiosConfig.post(`/businesses/${businessId}/block-unblock-customer`, businessPayload);
  return result.data;
}

export async function updateBusiness({
  updatedBusinessDataFromForm,
  businessId,
}: {
  updatedBusinessDataFromForm: UpdateBusinessDTO;
  businessId: string;
}) {
  const result = await axiosConfig.patch(`/businesses/${businessId}`, updatedBusinessDataFromForm);
  return result.data;
}

export async function getBusinessHours(businessId: string) {
  const result = await axiosConfig.get(`/businesses/hours/${businessId}`);
  return result.data;
}

export async function getEmployeesWorkingHours(businessId: string) {
  const result = await axiosConfig.get(`/businesses/employees-working-hours/${businessId}`);
  return result.data;
}

export async function getMajorHolidays() {
  const result = await axios.get('https://www.hebcal.com/hebcal?v=1&cfg=json&maj=on');
  return result.data;
}

export async function getBusinessImages(businessId: string) {
  const result = await axiosConfig.get(`/businesses/images/${businessId}`);
  return result.data;
}

export async function getMonthsSummary(businessId: string, year: number) {
  const result = await axiosConfig.get(`/businesses/months-reports/${businessId}`, {
    params: { year: `${Number(year)}` },
  });
  return result.data;
}

export async function getDashboardBasicStats(businessId: string) {
  const result = await axiosConfig.get(`/business/dashboard/basic-stats/${businessId}`);
  return result.data;
}

export async function getDashboardTreatmentsRevenue(businessId: string, months: string[]) {
  const result = await axiosConfig.get(`/business/dashboard/treatments-revenue/${businessId}?months=${months}`);
  return result.data;
}

export async function getDashboardTreatmentsAppointments(businessId: string, month: string, year: number) {
  const result = await axiosConfig.get(`/business/dashboard/treatments-appointments/${businessId}`, {
    params: { year: Number(year), month },
  });
  return result.data;
}

export async function getDashboardEmployeesAppointments(businessId: string, month: string, year: number) {
  const result = await axiosConfig.get(`/business/dashboard/employees-appointments/${businessId}?month=${month}&year=${Number(year)}`);
  return result.data;
}

export async function getDashboardEmployeesAbsences(businessId: string, month: string, year: number) {
  const result = await axiosConfig.get(`/business/dashboard/employees-absences/${businessId}?month=${month}&year=${Number(year)}`);
  return result.data;
}

export async function getDashboardSixMonths(businessId: string, months: string[]) {
  const result = await axiosConfig.get(`/business/dashboard/six-months/${businessId}?months=${months}`);
  return result.data;
}

export async function updateBusinessLogo({ businessDataFromForm, businessId }: { businessId: string; businessDataFromForm: { file: File } }) {
  // Create FormData
  const formData = new FormData();
  formData.append('file', businessDataFromForm.file);

  // Send FormData
  const result = await axiosConfig.post(`/businesses/${businessId}/logo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Ensure proper header for file uploads
    },
  });

  return result.data;
}

export async function updateBusinessBgImage({ businessDataFromForm, businessId }: { businessDataFromForm: { file: File }; businessId: string }) {
  // Create FormData
  const formData = new FormData();
  formData.append('file', businessDataFromForm.file);

  // Send FormData
  const result = await axiosConfig.post(`/businesses/${businessId}/bg-image`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Ensure proper header for file uploads
    },
  });

  return result.data;
}

export async function updateBusinessesImages({
  ImagePayload,
  businessId,
  imageNumber,
}: {
  ImagePayload: FormData;
  businessId: string;
  imageNumber: number;
}) {
  const result = await axiosConfig.post(`/businesses/${businessId}/${imageNumber}/business-images`, ImagePayload, {
    headers: {
      'Content-Type': 'multipart/form-data', // Ensure proper header for file uploads
    },
  });

  return result.data;
}
