import { useQuery } from 'react-query';
import { getBusinessHours } from 'services/api/business';
import { DAYS_OF_WEEK } from 'shared/consts';
import { DaysOfBusinessType } from 'shared/types/app.types';

export const useBusinessHours = ({ businessId }: { businessId: string }) => {
  const { data: businessHours, isLoading: isLoadingBusinessHours } = useQuery<DaysOfBusinessType[]>(
    ['businessHours'],
    () => getBusinessHours(businessId),
    {
      select: (response: DaysOfBusinessType[]) => {
        return response.sort((a, b) => {
          return DAYS_OF_WEEK.indexOf(a.dayOfWeek) - DAYS_OF_WEEK.indexOf(b.dayOfWeek);
        });
      },
    },
  );

  return { businessHours: businessHours ?? [], isLoadingBusinessHours };
};
