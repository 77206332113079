import { Button, Flex, Text, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface INumberInputProps {
  onChange: (value: any) => void;
  value: number;
  minValue: number;
  maxValue: number;
}

export default function NumberInput({ onChange, value, maxValue, minValue, ...rest }: INumberInputProps) {
  const toast = useToast();
  const { t } = useTranslation();
  const popToast = () => {
    toast({
      title: t('Your can not cross the limit'),
      description: t('There is a limit for this number'),
      status: 'info',
      duration: 3000,
      position: 'bottom',
      isClosable: true,
    });
  };

  const increment = () => {
    if (value < maxValue) {
      onChange(value + 1); // Increment value
    } else {
      popToast();
    }
  };

  const decrement = () => {
    if (value > minValue) {
      onChange(value - 1); // Decrement value
    } else {
      popToast();
    }
  };

  return (
    <Flex alignItems="center" gap={4}>
      {/* Decrement Button */}
      <Button
        onClick={decrement}
        size="sm"
        variant="brand"
        border="1px solid"
        borderColor="gray.600"
        _hover={{ bg: 'gray.700' }}
        _active={{ bg: 'gray.600' }}
        borderRadius="md"
      >
        –
      </Button>

      {/* Count Display */}
      <Text fontSize="lg" fontWeight="bold">
        {value}
      </Text>

      {/* Increment Button */}
      <Button
        onClick={increment}
        size="sm"
        variant="brand"
        border="1px solid"
        borderColor="gray.600"
        _hover={{ bg: 'gray.700' }}
        _active={{ bg: 'gray.600' }}
        borderRadius="md"
      >
        +
      </Button>
    </Flex>
  );
}
