import { mode } from '@chakra-ui/theme-tools';
export const globalStyles = {
  colors: {
    brand: {
      100: '#EAE6FF',
      200: '#D4C8FF',
      300: '#BBA5FF',
      400: '#9D82FF',
      500: '#7551FF',
      600: '#5D3ECB',
      700: '#452EA3',
      800: '#2E1F7B',
      900: '#1B124D',
    },
    steps: {
      100: '#FFE7E5',
      200: '#FFC8C1',
      300: '#FFA39D',
      400: '#FF827D',
      500: '#FF5C5A',
      600: '#DB4A4A',
      700: '#B83C3C',
      800: '#932F2F',
      900: '#741F1F',
    },
    brandScheme: {
      100: '#EDEAFF',
      200: '#D3CFFF',
      300: '#BAB3FF',
      400: '#9F97FF',
      500: '#846BFB',
      600: '#6A53DB',
      700: '#533EAA',
      800: '#3E2B83',
      900: '#2A1D5A',
    },
    brandTabs: {
      100: '#F4F2FF',
      200: '#DAD5FF',
      300: '#BEB5FF',
      400: '#A39AFF',
      500: '#8A7FFB',
      600: '#6F64DB',
      700: '#564DBA',
      800: '#403799',
      900: '#2A2372',
    },
    secondaryGray: {
      100: '#E0E5F2',
      200: '#E1E9F8',
      300: '#F4F7FE',
      400: '#E9EDF7',
      500: '#8F9BBA',
      600: '#A3AED0',
      700: '#707EAE',
      800: '#707EAE',
      900: '#1B2559',
    },
    red: {
      100: '#FFE9E9',
      200: '#FFC7C7',
      300: '#FF9E9E',
      400: '#FF7777',
      500: '#FF5050',
      600: '#DB4040',
      700: '#B73232',
      800: '#932525',
      900: '#741919',
    },
    blue: {
      100: '#E6F0FF',
      200: '#CCE0FF',
      300: '#99C1FF',
      400: '#66A3FF',
      500: '#3385FF',
      600: '#2768DB',
      700: '#1E4EB7',
      800: '#163893',
      900: '#102373',
    },
    orange: {
      100: '#FFF2DB',
      200: '#FFE4B7',
      300: '#FFD08C',
      400: '#FFBD62',
      500: '#FFAA38',
      600: '#DB8C2F',
      700: '#B77026',
      800: '#93561E',
      900: '#743F16',
    },
    green: {
      100: '#E9FAF3',
      200: '#C4F2E0',
      300: '#9CE9CC',
      400: '#76E1B9',
      500: '#4FD9A5',
      600: '#3CB384',
      700: '#2F8E67',
      800: '#226A4D',
      900: '#164B36',
    },
    navy: {
      100: '#E3EAFB', // Lightest shade, soft navy
      200: '#C2D0F5', // Slightly darker, gentle navy
      300: '#91A6EB', // Soft medium navy
      400: '#607CD7', // Muted navy blue
      500: '#2E52C2', // Standard navy
      600: '#2645A6', // Slightly deeper navy
      700: '#1E388A', // Darker rich navy
      800: '#172B6F', // Very dark navy
      900: '#101F55', // Deepest navy
    },
    gray: {
      100: '#F9FAFB',
      200: '#F0F2F5',
      300: '#E2E6EB',
      400: '#CCD3DC',
      500: '#B3BCC9',
      600: '#99A4B0',
      700: '#7E8A96',
      800: '#656F7A',
      900: '#4D5763',
    },
  },
  styles: {
    global: (props: any) => ({
      body: {
        overflowX: 'hidden',
        bg: mode('secondaryGray.300', 'navy.900')(props),
        fontFamily: 'heebo,  sans-serif',
        letterSpacing: '-0.5px',
      },
      input: {
        color: mode('gray700', 'navy.900'),
      },
      html: {
        fontFamily: 'DM Sans',
      },
      p: {
        marginBottom: '0px',
      },
    }),
  },
};
