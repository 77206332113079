import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { getEmployeesWorkingHours } from 'services/api/business';
import { ACTIVE } from 'shared/consts';
import { EmployeeWorkingHoursEntity } from 'shared/types/app.types';

export const useEmployeesWorkingHours = () => {
  const { userData } = useContext(AuthenticationContext);
  const { data: employeesWorkingHours, isLoading: isLoadingEmployeesWorkingHours } = useQuery<EmployeeWorkingHoursEntity[]>(
    ['employees-working-hours'],
    () => getEmployeesWorkingHours(userData.business.id),
    {
      enabled: userData.user.status.name === ACTIVE,
      refetchOnMount: false,
    },
  );

  return { employeesWorkingHours: employeesWorkingHours ?? [], isLoadingEmployeesWorkingHours };
};
