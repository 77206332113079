// Chakra imports
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
// Custom components
// Assets
import { Field, Form, Formik, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { BsInstagram } from 'react-icons/bs';
import { FaFacebook } from 'react-icons/fa';
import { CreateBusinessStep2 } from 'shared/types/app.types';

export default function CreateBusinessStep2Form({
  nextStep2,
  prevStep,
  step2,
}: {
  nextStep2: (values: CreateBusinessStep2) => void;
  prevStep: () => void;
  step2: CreateBusinessStep2;
}) {
  const { t } = useTranslation();
  const facebookColor = '#1877F2'; // Official Facebook blue
  const instaColor = '#833AB4'; // Instagram purple

  const submitStep3 = (values: CreateBusinessStep2) => {
    nextStep2({ ...values });
  };

  const disableCreateButton = (errors: FormikErrors<CreateBusinessStep2>) => {
    return !!Object.keys(errors).length;
  };

  return (
    <>
      <Formik
        initialValues={{
          instagram: step2.instagram ?? '',
          facebook: step2.facebook ?? '',
        }}
        onSubmit={submitStep3}
      >
        {(props) => (
          <Form>
            <Field name="instagram" key="instagram">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="10px" isInvalid={form.errors.instagram && form.touched.instagram}>
                  <FormLabel htmlFor="instagram">{`${t('Instagram')} (${t('not required')}) `}</FormLabel>

                  <InputGroup size="md">
                    <Input
                      {...field}
                      id="instagram"
                      variant={'auth'}
                      fontSize="md"
                      ms={{ base: '0px', md: '0px' }}
                      type="name"
                      placeholder={t('Your instagram')}
                      fontWeight="500"
                      size="md"
                    />
                    <InputLeftElement display="flex" alignItems="center">
                      <Icon color={instaColor} as={BsInstagram} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.instagram}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="facebook">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="10px">
                  <FormLabel htmlFor="facebook">{`${t('Facebook')} (${t('not required')}) `}</FormLabel>

                  <InputGroup size="md">
                    <Input
                      {...field}
                      id="facebook"
                      variant={'auth'}
                      fontSize="md"
                      type="facebook"
                      placeholder={t('Your facebook')}
                      fontWeight="500"
                      size="md"
                    />
                    <InputLeftElement display="flex" alignItems="center">
                      <Icon color={facebookColor} as={FaFacebook} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.facebook}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex pt="10px" gap={2} justifyContent={'end'}>
              <Button variant="action" fontSize="md" fontWeight="500" px="24px" onClick={prevStep} py="5px">
                {t('Back')}
              </Button>
              <Button
                variant="brand"
                color="white"
                fontSize="md"
                type="submit"
                fontWeight="500"
                isDisabled={disableCreateButton(props.errors)}
                px="24px"
                py="5px"
              >
                {t('Next')}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}
