import { FormControl, FormLabel, Switch, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const ToggleWeekendView = ({
  weekendsVisible,
  moreThanOneEmployee,
  handleWeekendsToggle,
}: {
  handleWeekendsToggle: () => void;
  weekendsVisible: boolean;
  moreThanOneEmployee: boolean;
}) => {
  const toggleBg = useColorModeValue('brand.400', 'brand.600');
  const { t } = useTranslation();
  return (
    <FormControl
      sx={{
        position: 'fixed',
        float: 'middle',
        width: '150px',
        height: { base: '25px', lg: '40px' },
        bottom: { base: moreThanOneEmployee ? '45px' : '12px', lg: '20px' },
        right: { base: '120px', lg: '700px' },
        border: '1px solid',
        borderColor: 'brand.200',
        backgroundColor: toggleBg,
        borderRadius: '50px',
        // backgroundColor: 'red',
        justifyContent: 'center',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        zIndex: 1000,
      }}
      display="flex"
      alignItems="center"
      onChange={handleWeekendsToggle}
      // mb="15px"
      // mt={{ base: '50px', lg: '100px' }}
    >
      <FormLabel htmlFor="email-alerts" mb="0">
        <Text fontSize="18px" color={'white'}>
          {t('Weekend')}?
        </Text>
      </FormLabel>
      <Switch isChecked={weekendsVisible} sx={{ direction: 'ltr' }} />
    </FormControl>
  );
};
