import { Box, Flex, Select } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { BUSINESS_OPENING_CLOSE_TIMES, DEFAULT_END_TIME, DEFAULT_START_TIME } from 'shared/consts';
import { getTimeInMinutes } from 'shared/helpersFunctions';
import { DaysOfWeekType } from 'shared/types/app.types';

interface TimeAndDayProps {
  onChangeTime: (time: string, day: DaysOfWeekType, openOrClose: 'O' | 'C') => void;
  day: DaysOfWeekType;
  openTime?: string;
  closeTime?: string;
  isOpen: boolean;
  isReadOnly: boolean;
}

const TimeAndDay: FunctionComponent<TimeAndDayProps> = ({ onChangeTime, day, openTime, closeTime, isOpen, isReadOnly }: TimeAndDayProps) => {
  const { t } = useTranslation();
  const businessTimes = BUSINESS_OPENING_CLOSE_TIMES;
  const minutesA = getTimeInMinutes(openTime ?? DEFAULT_START_TIME);
  const minutesB = getTimeInMinutes(closeTime ?? DEFAULT_END_TIME);
  const isTimeValid = minutesB - minutesA > 0;

  return (
    <Box>
      <Flex alignItems={'center'} sx={isTimeValid ? {} : { border: '1px solid', borderColor: 'red', borderRadius: '6px' }}>
        <Select
          variant="filled"
          title="about"
          placeholder={t('Close')}
          disabled={!isOpen || isReadOnly}
          defaultValue={closeTime ?? DEFAULT_END_TIME}
          sx={{ direction: 'ltr' }}
          onChange={(e) => onChangeTime(e.target.value, day, 'C')}
        >
          {businessTimes.map((item) => {
            return (
              <option key={item} value={`${item}`}>
                {item}
              </option>
            );
          })}
        </Select>
        <Box mx={1}>{'-'}</Box>
        <Select
          onChange={(e) => onChangeTime(e.target.value, day, 'O')}
          sx={{ direction: 'ltr' }}
          defaultValue={openTime ?? DEFAULT_START_TIME}
          disabled={!isOpen || isReadOnly}
          variant="filled"
          placeholder={t('Open')}
        >
          {businessTimes.map((item) => {
            return (
              <option key={item} value={`${item}`}>
                {item}
              </option>
            );
          })}
        </Select>
      </Flex>
      {!isTimeValid && <Flex sx={{ justifyContent: 'center', alignItems: 'end', height: '18px', color: 'red.400' }}>{t('Invalid time')}</Flex>}
    </Box>
  );
};

export default TimeAndDay;
