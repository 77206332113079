import { Box } from '@chakra-ui/react';
import { DatesSetArg } from '@fullcalendar/core';
import { useContext, useMemo, useState } from 'react';

import { AuthenticationContext } from 'providers/AuthProvider';
import { serializeBusinessHours } from 'shared/helpersFunctions';
import { useBusinessHours } from 'shared/hooks/useBusinessHours';
import { useSerializedAppointmentsByGivenDates } from 'shared/hooks/useSerializedAppointmentsByGivenDates';
import Calendar from './Calendar';

export type DatesSelection = {
  fromDate: string;
  toDate: string;
  date: Date;
  view: string;
  day?: number;
};

const MainCalendar = () => {
  const { userData } = useContext(AuthenticationContext);
  const { businessHours } = useBusinessHours({
    businessId: userData?.business.id,
  });
  const businessHoursSerialized = serializeBusinessHours(businessHours ?? []);
  const chosenDates: DatesSelection = useMemo(() => {
    const currentDate = new Date();
    return {
      fromDate: currentDate.toLocaleDateString(),
      toDate: currentDate.toLocaleDateString(),
      date: currentDate,
      view: 'timeGridDay',
      day: currentDate.getDay(),
    };
  }, []);
  const [dateSelection, setDatesSelection] = useState<DatesSelection>({
    fromDate: chosenDates.fromDate,
    toDate: chosenDates.toDate,
    date: chosenDates.date,
    view: 'timeGridWeek',
  });

  const { appointmentsOfDate } = useSerializedAppointmentsByGivenDates({
    businessId: userData.business.id,
    fromDate: dateSelection.fromDate,
    toDate: dateSelection.toDate,
    businessData: userData.business,
  });

  // const handleEventClick = (clickInfo: EventClickArg) => {
  //   if (
  //     // eslint-disable-next-line no-restricted-globals
  //     confirm(
  //       `Are you sure you want to delete the event '${clickInfo.event.title}'`
  //     )
  //   ) {
  //     clickInfo.event.remove();
  //   }
  // };

  // useEffect(() => {
  //   // setWeekendsVisible(chosenDates.day >= 5);
  //   setDatesSelection(chosenDates);
  // }, [chosenDates]);

  // const handleDateSelect = (selectInfo: DateSelectArg) => {
  //   let title = prompt("Please enter a new title for your event");
  //   let calendarApi = selectInfo.view.calendar;

  //   calendarApi.unselect(); // clear date selection

  //   if (title) {
  //     calendarApi.addEvent({
  //       id: createEventId(),
  //       title,
  //       start: selectInfo.startStr,
  //       end: selectInfo.endStr,
  //       allDay: selectInfo.allDay,
  //     });
  //   }
  // };

  const setDateFromMainCalendar = (dates: DatesSetArg): void => {
    const newGivenDates = {
      fromDate: dates.start.toLocaleDateString('en-US'),
      toDate: dates.end.toLocaleDateString('en-US'),
      date: dates.start,
      view: dates.view.type,
    };
    if (newGivenDates.fromDate === dateSelection.fromDate && newGivenDates.toDate === dateSelection.toDate) {
      return;
    }
    setDatesSelection(() => {
      return { ...newGivenDates };
    });
  };

  // const invalidateCalendar = async () => {
  //   await queryClient.invalidateQueries(`calendar`);
  // };

  // const handleEvents = (events: EventApi[]) => {
  //   setCurrentEvents((prevEvents: EventApi[]) => {
  //     return [...prevEvents, ...events];
  //   });
  // };

  return (
    <>
      {/* {this.renderSidebar()} */}
      <Box mt={{ base: '60px', md: '20px' }}>
        <Calendar
          // key={dateChangeTimeStamp}
          dateSelection={dateSelection}
          businessHoursSerialized={businessHoursSerialized}
          appointmentsOfDate={appointmentsOfDate}
          setDateFromMainCalendar={setDateFromMainCalendar}
        />
      </Box>
    </>
  );
};

//   renderSidebar() {
//     return (
//       <div className="demo-app-sidebar">
//         <div className="demo-app-sidebar-section">
//           <h2>Instructions</h2>
//           <ul>
//             <li>Select dates and you will be prompted to create a new event</li>
//             <li>Drag, drop, and resize events</li>
//             <li>Click an event to delete it</li>
//           </ul>
//         </div>
//         <div className="demo-app-sidebar-section">
//           <label>
//             <input
//               type="checkbox"
//               checked={this.state.weekendsVisible}
//               onChange={this.handleWeekendsToggle}
//             ></input>
//             toggle weekends
//           </label>
//         </div>
//         <div className="demo-app-sidebar-section">
//           <h2>All Events ({this.state.currentEvents.length})</h2>
//           <ul>{this.state.currentEvents.map(renderSidebarEvent)}</ul>
//         </div>
//       </div>
//     );
//   }

// function renderEventContent(eventContent: EventContentArg) {
//   return (
//     <Box sx={{ backgroundColor: "red" }}>
//       <b>{eventContent.timeText}</b>
//       <i>{eventContent.event.title}</i>
//     </Box>
//   );
// }

// function renderSidebarEvent(event: EventApi) {
//   return (
//     <li key={event.id}>
//       <b>
//         {formatDate(event.start!, {
//           year: "numeric",
//           month: "short",
//           day: "numeric",
//         })}
//       </b>
//       <i>{event.title}</i>
//     </li>
//   );
// }

export default MainCalendar;
