import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { EmployeeWorkingHours } from 'components/openingHours/EmployeeWorkingHours';
import { Field, Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { cloneDeep } from 'lodash';
import { AuthenticationContext } from 'providers/AuthProvider';
import { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDesignServices, MdFemale, MdImage, MdMale, MdPerson, MdPhone } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';
import { createEmployee, updateEmployee } from 'services/api/employee';
import { FlavorOption, SelectCustomComponents, TreatmentsSelectCustomComponents } from 'shared/components/SelectComponents';
import { FEMALE, GENDER_OPTIONS, MALE } from 'shared/consts';
import { areEmployeeWorkingHoursDifferent, getTimeInMinutes, serializeTreatments } from 'shared/helpersFunctions';
import { useBusinessHours } from 'shared/hooks/useBusinessHours';
import { useTreatments } from 'shared/hooks/useTreatments';
import {
  DaysOfWeekType,
  EmployeeDaysOfWeekType,
  EmployeeEntityType,
  EmployeeWorkingHoursEntity,
  TreatmentEntityType,
  TreatmentSelectType,
} from 'shared/types/app.types';
import validator from 'validator';

interface IAddEmployeeFormProps {
  handleCloseFunction: () => void;
  employeeWorkingHours: EmployeeWorkingHoursEntity[];
  employeeToEditOrDelete?: EmployeeEntityType;
}

interface IEmployeeForm {
  fullName: string;
  phone: string;
  email: string;
  treatments: (TreatmentEntityType & FlavorOption)[];
}

const AddEmployeeForm: FunctionComponent<IAddEmployeeFormProps> = ({
  handleCloseFunction,
  employeeWorkingHours,
  employeeToEditOrDelete,
}: IAddEmployeeFormProps) => {
  const textColorSecondary = 'gray.400';
  const [chosenTreatments, setChosenTreatments] = useState<TreatmentSelectType[]>(
    employeeToEditOrDelete ? serializeTreatments(employeeToEditOrDelete.treatments) : [],
  );
  const { t } = useTranslation();
  const [gender, setGender] = useState<FlavorOption>(employeeToEditOrDelete && employeeToEditOrDelete.gender === MALE.value ? MALE : FEMALE);
  const [employeeWorkingDays, setEmployeeWorkingDays] = useState<EmployeeDaysOfWeekType>(cloneDeep(employeeWorkingHours));
  const { userData } = useContext(AuthenticationContext);
  const { businessHours } = useBusinessHours({
    businessId: userData?.business.id,
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { treatments, isLoadingTreatments } = useTreatments(true);

  const queryClient = useQueryClient();
  const toast = useToast();
  const startFullName = employeeToEditOrDelete ? `${employeeToEditOrDelete.firstName} ${employeeToEditOrDelete.lastName}` : '';
  const startEmail = employeeToEditOrDelete ? employeeToEditOrDelete.email : '';
  const startPhone = employeeToEditOrDelete ? employeeToEditOrDelete.phone : '';

  const { mutate: updateEmployeeMutation, isLoading: isLoadingEmployeeUpdate } = useMutation(updateEmployee, {
    onSuccess: async (response) => {
      setTimeout(async () => {
        await queryClient.invalidateQueries('treatments');
        await queryClient.invalidateQueries('employees');
        await queryClient.invalidateQueries('employees-working-hours');
      }, 1000);
      toast({
        title: t('Employee Updated Successfully'),
        description: t('successfully updated employee, be aware that this employee might have appointments related to it.'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      handleCloseFunction();
    },
    onError: () => {
      toast({
        title: t('Employee Deleted Error'),
        description: t('There was an error deleting the employee try again later'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const { mutate: createEmployeeMutation, isLoading: isLoadingEmployeeCreate } = useMutation(createEmployee, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries('employees');
      await queryClient.invalidateQueries('treatments');
      toast({
        title: t('Employee Created Successfully'),
        description: t('successfully created employee, be aware that this employee might have appointments related to it.'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      handleCloseFunction();
    },
    onError: (error) => {
      const description =
        error &&
        typeof error === 'object' &&
        'response' in error &&
        error.response &&
        typeof error.response === 'object' &&
        'status' in error.response &&
        error.response.status === 400
          ? t('Phone number already exists')
          : t('Ops error occurred try again later');
      toast({
        title: t('Employee Created Error'),
        description: description,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleFormSubmit = (values: IEmployeeForm, actions: FormikHelpers<IEmployeeForm>) => {
    const fullNameArray = values.fullName.trim().split(' ');
    const lastName = fullNameArray.slice(1).join(' ');
    if (employeeToEditOrDelete) {
      updateEmployeeMutation({
        updatedEmployee: {
          firstName: fullNameArray[0],
          lastName: lastName,
          email: values.email,
          phone: values.phone,
          daysOfEmployee: employeeWorkingDays,
          gender: gender.value as 'M' | 'F',
          treatments: chosenTreatments.map((treat) => treat.value),
        },
        file: selectedFile,
        employeeId: employeeToEditOrDelete.id,
      });
    } else {
      createEmployeeMutation({
        email: values.email,
        phone: values.phone,
        firstName: fullNameArray[0],
        lastName: lastName ?? '',
        businessId: userData.business.id,
        gender: gender.value as 'M' | 'F',
        treatments: chosenTreatments.map((treat) => treat.value),
        file: selectedFile,
        daysOfEmployee: employeeWorkingDays,
      });
    }
  };

  const handleChangeOnDayToggle = (dayOfWeek: DaysOfWeekType) => {
    if (employeeWorkingDays.map((dayItem) => dayItem.dayOfWeek).some((dayItem) => dayItem === dayOfWeek)) {
      setEmployeeWorkingDays(employeeWorkingDays.filter((dayItem) => dayItem.dayOfWeek !== dayOfWeek));
    } else {
      const businessDay = businessHours.find((dayItem) => dayItem.dayOfWeek === dayOfWeek);
      setEmployeeWorkingDays((prev) => [
        ...prev,
        {
          dayOfWeek,
          openTime: businessDay.openTime,
          closeTime: businessDay.closeTime,
          followsBusinessHours: true,
        },
      ]);
    }
  };

  const onChangeTime = (time: string, dayOfWeek: DaysOfWeekType, openOrClose: 'O' | 'C') => {
    setEmployeeWorkingDays(
      employeeWorkingDays.map((dayItem) => {
        if (dayItem.dayOfWeek === dayOfWeek) {
          if (openOrClose === 'C') {
            dayItem.closeTime = time;
          } else {
            dayItem.openTime = time;
          }
        }
        return dayItem;
      }),
    );
  };

  const handleFollowBusinessCheck = (dayOfWeek: DaysOfWeekType) => {
    if (employeeWorkingDays.some((dayItem) => dayItem.dayOfWeek === dayOfWeek)) {
      const businessDay = businessHours.find((dayItem) => dayItem.dayOfWeek === dayOfWeek);
      setEmployeeWorkingDays((prevEmployeeWorkingDays) =>
        prevEmployeeWorkingDays.map((dayItem) => {
          if (dayItem.dayOfWeek === dayOfWeek) {
            dayItem.followsBusinessHours = !dayItem.followsBusinessHours;
            dayItem.openTime = businessDay.openTime;
            dayItem.closeTime = businessDay.closeTime;
          }
          return dayItem;
        }),
      );
    }
  };

  const validateIfToDisableButton = (values: IEmployeeForm, errors: FormikErrors<IEmployeeForm>) => {
    const chosenTreatmentsNames = chosenTreatments.map((treat) => treat.name).sort();
    const employeeTimeIsInvalid = employeeWorkingDays.some(
      (workday) => getTimeInMinutes(workday.closeTime) - getTimeInMinutes(workday.openTime) <= 0,
    );
    const employeeWorkingTimeIsDifferent = areEmployeeWorkingHoursDifferent(employeeWorkingDays, employeeWorkingHours);
    const treatmentFromExistedEmployee = employeeToEditOrDelete ? employeeToEditOrDelete.treatments.map((treat) => treat.name).sort() : [];
    return (
      !!Object.keys(errors).length ||
      !values.fullName ||
      employeeTimeIsInvalid ||
      !values.email ||
      !values.phone ||
      (employeeToEditOrDelete &&
        employeeToEditOrDelete.gender === gender.value &&
        values.phone === employeeToEditOrDelete.phone &&
        !employeeWorkingTimeIsDifferent &&
        values.email === employeeToEditOrDelete.email &&
        selectedFile === null &&
        values.fullName === `${employeeToEditOrDelete.firstName} ${employeeToEditOrDelete.lastName}` &&
        chosenTreatmentsNames.length === treatmentFromExistedEmployee.length &&
        chosenTreatmentsNames.every((value, index) => value === treatmentFromExistedEmployee[index]))
    );
  };
  const displaySpinner = isLoadingEmployeeCreate || isLoadingEmployeeUpdate;
  return (
    <Box>
      <Formik
        initialValues={{
          fullName: startFullName,
          email: startEmail,
          phone: startPhone,
          treatments: chosenTreatments,
        }}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting, values, errors }) => (
          <Form>
            <Field name="Photo">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="10px">
                  <FormLabel htmlFor="photo">{t('Photo')}</FormLabel>
                  <InputGroup size="md">
                    <Input type="file" variant="auth" accept="image/*" onChange={(e) => setSelectedFile(e.target.files?.[0] || null)} />
                    <InputLeftElement display="flex" alignItems="center">
                      <Icon color={textColorSecondary} as={MdImage} />
                    </InputLeftElement>
                  </InputGroup>
                </FormControl>
              )}
            </Field>
            <Field
              name="fullName"
              validate={(fullName: string) =>
                !fullName || fullName.trim().split(' ').length < 2 ? t('Please provide a first name and last name.') : ''
              }
            >
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="10px" isRequired={true} isInvalid={form.errors.fullName && form.touched.fullName}>
                  <FormLabel htmlFor="fullName">{t('Full Name')}</FormLabel>
                  <InputGroup size="md">
                    <Input
                      {...field}
                      id="fullName"
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      type="fullName"
                      placeholder={t('Employee Name')}
                      fontWeight="500"
                      size="md"
                    />
                    <InputLeftElement display="flex" alignItems="center">
                      <Icon color={textColorSecondary} as={MdPerson} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.fullName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="phone" validator={(phone: string) => (!validator.isMobilePhone(phone, 'he-IL') ? t('Enter a valid phone') : '')}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="20px" isInvalid={form.errors.phone && form.touched.phone} isRequired={true}>
                  <FormLabel htmlFor="phone">{t('Phone')}</FormLabel>
                  <InputGroup size="md">
                    <Input
                      {...field}
                      id="phone"
                      fontSize="sm"
                      placeholder="0502223333"
                      size="md"
                      type={'tel'}
                      variant="auth"
                      sx={{ textAlign: 'right' }}
                    />
                    <InputLeftElement display="flex" alignItems="center">
                      <Icon color={textColorSecondary} as={MdPhone} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="gender">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="20px" isInvalid={form.errors.gender && form.touched.gender}>
                  <FormLabel htmlFor="gender">{t('Gender')}</FormLabel>
                  <InputGroup>
                    <Box w="50%">
                      <Select
                        value={{ ...gender, label: t(gender.label) }}
                        components={SelectCustomComponents}
                        options={GENDER_OPTIONS.map((gender) => {
                          return { ...gender, label: t(gender.label) };
                        })}
                        onChange={(gender) => {
                          setGender(gender);
                        }}
                        variant="auth"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 2000,
                          }),
                        }}
                      />
                    </Box>
                    <InputLeftElement display="flex" alignItems="center" w="48%" justifyContent={'start'}>
                      <Icon w="20px" h="20px" color={textColorSecondary} as={MdMale} />
                      <Icon w="20px" h="20px" color={textColorSecondary} as={MdFemale} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.gender}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="treatments">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="20px" isInvalid={form.errors.treatments && form.touched.treatments}>
                  <FormLabel htmlFor="treatments">{t('Treatments related')}</FormLabel>
                  <InputGroup>
                    <Box w="90%">
                      {!isLoadingTreatments ? (
                        <Select
                          isMulti
                          value={chosenTreatments}
                          components={TreatmentsSelectCustomComponents}
                          options={treatments}
                          variant="auth"
                          placeholder={t('Select treatments')}
                          onChange={(treatments) => setChosenTreatments([...treatments])}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (provided) => ({
                              ...provided,
                              zIndex: 2000,
                            }),
                          }}
                        />
                      ) : (
                        <Spinner />
                      )}
                    </Box>
                    <InputLeftElement display="flex" alignItems="center" mt="4px">
                      <Icon color={textColorSecondary} as={MdDesignServices} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.treatments}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="hours">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="20px" isInvalid={form.errors.treatments && form.touched.treatments}>
                  <FormLabel htmlFor="treatments">{t('Working Days')}</FormLabel>
                  <EmployeeWorkingHours
                    handleChangeOnDayToggle={handleChangeOnDayToggle}
                    onChangeTime={onChangeTime}
                    handleFollowBusinessCheck={handleFollowBusinessCheck}
                    businessHours={businessHours}
                    isReadOnly={false}
                    daysOfEmployee={employeeWorkingDays}
                  />
                  <FormErrorMessage>{form.errors.treatments}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex gap="20px" justifyContent={'end'} mt="30px">
              <Button onClick={handleCloseFunction}>{t('Cancel')}</Button>
              <Button
                isLoading={displaySpinner}
                colorScheme="teal"
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                isDisabled={validateIfToDisableButton(values, errors)}
              >
                {!employeeToEditOrDelete ? t('Create') : t('Update')}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddEmployeeForm;
