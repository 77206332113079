import { Icon, IconButton, useColorModeValue } from '@chakra-ui/react';
import { debounce } from 'lodash';
import { MdRefresh } from 'react-icons/md';
import { useQueryClient } from 'react-query';

export const RefreshAppointmentIcon = () => {
  const color = useColorModeValue('brand.400', 'brand.500');
  const queryClient = useQueryClient();

  // Debounce to prevent spamming
  const debouncedInvalidateCalendar = debounce(() => {
    queryClient.invalidateQueries('calendar');
  }, 700);

  return (
    <IconButton
      aria-label="refresh"
      onClick={debouncedInvalidateCalendar}
      borderRadius="50%"
      sx={{
        position: 'fixed',
        float: 'right',
        width: '60px',
        height: '60px',
        bottom: { base: '8px', lg: '12px' },
        right: { base: '8px', lg: 310 },
        backgroundColor: color,
        borderRadius: '50px',
        justifyContent: 'center',
        textAlign: 'center',
        boxShadow: `1px 1px 1px black`,
        display: 'flex',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <Icon as={MdRefresh} color="white" h="25px" w="25px" />
    </IconButton>
  );
};
