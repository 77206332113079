import { Box, Button, Icon, Modal, ModalBody, ModalContent, ModalOverlay, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import banner from 'assets/img/business/bg-business.jpg';
import MiniCard from 'components/cards/MiniCard';
import PricingCard from 'components/cards/PricingCard';
import IconBox from 'components/icons/IconBox';
import ImageGridWithEdit from 'components/inputs/ImageGridWithEdit';
import { AuthenticationContext } from 'providers/AuthProvider';
import { FunctionComponent, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCalendar2Check, BsInstagram } from 'react-icons/bs';
import { FaBriefcase, FaFacebook, FaLock, FaLockOpen, FaRegCalendar } from 'react-icons/fa';
import { MdBusiness, MdDescription, MdEmail, MdLocationCity, MdPalette, MdPhone, MdProductionQuantityLimits, MdTimer } from 'react-icons/md';
import { ADMIN } from 'shared/consts';
import { BusinessDaysOfWeekType, BusinessEntityType, DaysOfBusinessType } from 'shared/types/app.types';
import UpdateOrCreateBusiness from 'views/business/UpdateOrCreateBusiness';
import OperatingHoursCard from '../components/OpeningBusinessHours';

export type UpdateBusinessForm = {
  businessName: string;
  phone: string;
  email: string;
  address: string;
  color: string;
  daysOfBusiness: BusinessDaysOfWeekType;
};

interface BusinessSettingsSectionProps {
  business: BusinessEntityType;
  businessHours: DaysOfBusinessType[];
}

const BusinessSettingsSection: FunctionComponent<BusinessSettingsSectionProps> = ({ business, businessHours }: BusinessSettingsSectionProps) => {
  const { isLoadingUpdateBusiness, userData } = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const [isOnEditMode, setIsOnEditMode] = useState<boolean>(false);

  const initialRef = useRef();
  const finalRef = useRef();

  const textColorSecondary = 'gray.400';

  const brandColor = useColorModeValue('brand.600', 'brand.100');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 3 }} gap="10px" mb="20px">
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={MdBusiness} color={brandColor} />} />}
          name={t('Name')}
          value={business.name}
        />
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={MdPhone} color={brandColor} />} />}
          name={t('Phone')}
          value={business.phone}
        />
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={MdEmail} color={brandColor} />} />}
          name={t('Email')}
          value={business.email}
        />
        {business.facebook && (
          <MiniCard
            startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={FaFacebook} color={brandColor} />} />}
            name={t('Facebook')}
            value={business.facebook}
          />
        )}
        {business.instagram && (
          <MiniCard
            startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={BsInstagram} color={brandColor} />} />}
            name={t('Instagram')}
            value={business.instagram}
          />
        )}

        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={MdLocationCity} color={brandColor} />} />}
          name={t('Address')}
          value={`${business.address.city} - ${business.address.street} - ${business.address.country}`}
        />
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={business.color} icon={<Icon w="22px" h="22px" as={MdPalette} color={brandColor} />} />}
          businessColor={business.color}
          name={t('Color')}
          value={business.color}
        />
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={FaBriefcase} color={brandColor} />} />}
          name={t('Type')}
          value={t(business.businessType)}
        />
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={MdTimer} color={brandColor} />} />}
          name={t('Hours of cancelation in advance')}
          value={`${business.hoursOfCancelationInAdvance} ${t('Hours')}`}
        />
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={FaRegCalendar} color={brandColor} />} />}
          name={t('Max days ahead that appointment can be settled')}
          value={`${business.maxFutureAppointmentDays} ${t('Days')}`}
        />
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={BsCalendar2Check} color={brandColor} />} />}
          name={t('Max of future appointments')}
          value={`${business.maxOfFutureAppointments} ${t('Appointments')}`}
        />
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={MdProductionQuantityLimits} color={brandColor} />} />}
          name={t('Number of treatments per appointment')}
          value={`${business.numberOfTreatmentsPerAppointment} ${t('Treatments')}`}
        />
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={MdDescription} color={brandColor} />} />}
          name={t('Description')}
          value={business.description}
        />

        <OperatingHoursCard businessHours={businessHours} />
      </SimpleGrid>
      <Box width={'100%'} justifyContent={'center'} display="flex">
        <Button
          isLoading={isLoadingUpdateBusiness}
          className="shadow-3xl"
          isDisabled={userData.user.role.name !== ADMIN}
          colorScheme="teal"
          onClick={() => setIsOnEditMode(!isOnEditMode)}
          type={isOnEditMode ? 'button' : 'submit'}
          fontSize={isOnEditMode ? 'md' : 'sm'}
          variant={'brand'}
          fontWeight={isOnEditMode ? '900' : '500'}
          leftIcon={isOnEditMode ? <Icon color={textColorSecondary} as={FaLockOpen} /> : <Icon color={textColorSecondary} as={FaLock} />}
          border={'1px solid'}
          w="100%"
          maxWidth={'250px'}
          h="50"
        >
          {isOnEditMode ? t('Save changes') : t('Edit Business')}
        </Button>
      </Box>

      <ImageGridWithEdit banner={banner} businessId={business.id} />
      <PricingCard chosenPlan={business.plan} />
      <Modal
        size="lg"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOnEditMode}
        scrollBehavior={'outside'}
        onClose={() => setIsOnEditMode(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody pb={2}>
            <UpdateOrCreateBusiness closeModal={() => setIsOnEditMode(false)} business={business} businessHours={businessHours} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BusinessSettingsSection;
