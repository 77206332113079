// Chakra imports
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, InputGroup, useColorModeValue } from '@chakra-ui/react';
import NumberInput from 'components/inputs/NumberInput';

// Custom components
// Assets
import { Field, Form, Formik, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { BUSINESS_SETTINGS_CONFIG } from 'shared/consts';
import { BusinessEntityType, CreateBusinessStep6 } from 'shared/types/app.types';

export default function CreateBusinessStep6Form({
  nextStep6,
  prevStep,
  business,
  isUpdate,
}: {
  nextStep6: (values: CreateBusinessStep6) => void;
  prevStep: () => void;
  business?: BusinessEntityType;
  isUpdate: boolean;
}) {
  const errorColorBrand = useColorModeValue('red.500', 'red.300');
  const { t } = useTranslation();

  const submitStep5nextStep6 = (values: CreateBusinessStep6) => {
    nextStep6(values);
  };

  const disableCreateButton = (errors: FormikErrors<CreateBusinessStep6>) => {
    return !!Object.keys(errors).length;
  };

  return (
    <>
      <Formik
        initialValues={{
          numberOfTreatmentsPerAppointment:
            business?.numberOfTreatmentsPerAppointment ?? BUSINESS_SETTINGS_CONFIG.NUMBER_OF_TREATMENTS_PER_APPOINTMENT.initial,
          hoursOfCancelationInAdvance: business?.hoursOfCancelationInAdvance ?? BUSINESS_SETTINGS_CONFIG.HOURS_OF_CANCELATION_IN_ADVANCE.initial,
          maxOfFutureAppointments: business?.maxOfFutureAppointments ?? BUSINESS_SETTINGS_CONFIG.MAX_OF_FUTURE_APPOINTMENTS.initial,
          maxFutureAppointmentDays: business?.maxFutureAppointmentDays ?? BUSINESS_SETTINGS_CONFIG.MAX_OF_FUTURE_APPOINTMENT_DAYS.initial,
        }}
        onSubmit={submitStep5nextStep6}
      >
        {({ errors, setFieldValue }) => (
          <Form>
            <Field name="numberOfTreatmentsPerAppointment">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  mb="20px"
                  isInvalid={form.errors.numberOfTreatmentsPerAppointment && form.touched.numberOfTreatmentsPerAppointment}
                  // isRequired={true}
                >
                  <FormLabel htmlFor="numberOfTreatmentsPerAppointment">{t('Maximum number of treatments per appointment')}</FormLabel>
                  <InputGroup size="md">
                    <Flex flexDir={'column'} gap="20px">
                      <NumberInput
                        {...field}
                        minValue={BUSINESS_SETTINGS_CONFIG.NUMBER_OF_TREATMENTS_PER_APPOINTMENT.min}
                        maxValue={BUSINESS_SETTINGS_CONFIG.NUMBER_OF_TREATMENTS_PER_APPOINTMENT.max}
                        onChange={(newValue) => setFieldValue('numberOfTreatmentsPerAppointment', newValue)}
                      />
                    </Flex>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.numberOfTreatmentsPerAppointment}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="hoursOfCancelationInAdvance">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  mb="20px"
                  isInvalid={form.errors.hoursOfCancelationInAdvance && form.touched.hoursOfCancelationInAdvance}
                  // isRequired={true}
                >
                  <FormLabel htmlFor="hoursOfCancelationInAdvance">
                    {t('Amount of hours in advance that clients will be allowed to cancel')}
                  </FormLabel>
                  <InputGroup size="md">
                    <Flex flexDir={'column'} gap="20px">
                      <NumberInput
                        {...field}
                        minValue={BUSINESS_SETTINGS_CONFIG.HOURS_OF_CANCELATION_IN_ADVANCE.min}
                        maxValue={BUSINESS_SETTINGS_CONFIG.HOURS_OF_CANCELATION_IN_ADVANCE.max}
                        onChange={(newValue) => setFieldValue('hoursOfCancelationInAdvance', newValue)}
                      />
                    </Flex>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.hoursOfCancelationInAdvance}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="maxFutureAppointmentDays">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="20px" isInvalid={form.errors.maxFutureAppointmentDays && form.touched.maxFutureAppointmentDays}>
                  <FormLabel htmlFor="maxFutureAppointmentDays">{t('Max days ahead that appointment can be settled')}</FormLabel>
                  <InputGroup size="md">
                    <Flex flexDir={'column'} gap="20px">
                      <NumberInput
                        {...field}
                        minValue={BUSINESS_SETTINGS_CONFIG.MAX_OF_FUTURE_APPOINTMENT_DAYS.min}
                        maxValue={BUSINESS_SETTINGS_CONFIG.MAX_OF_FUTURE_APPOINTMENT_DAYS.max}
                        onChange={(newValue) => setFieldValue('maxFutureAppointmentDays', newValue)}
                      />
                    </Flex>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.maxFutureAppointmentDays}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="maxOfFutureAppointments">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  mb="20px"
                  isInvalid={form.errors.maxOfFutureAppointments && form.touched.maxOfFutureAppointments}
                  // isRequired={true}
                >
                  <FormLabel htmlFor="maxOfFutureAppointments">{t('Maximum number of future per appointments client can have')}</FormLabel>
                  <InputGroup size="md">
                    <Flex flexDir={'column'} gap="20px">
                      <NumberInput
                        {...field}
                        minValue={BUSINESS_SETTINGS_CONFIG.MAX_OF_FUTURE_APPOINTMENTS.min}
                        maxValue={BUSINESS_SETTINGS_CONFIG.MAX_OF_FUTURE_APPOINTMENTS.max}
                        onChange={(newValue) => setFieldValue('maxOfFutureAppointments', newValue)}
                      />
                    </Flex>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.maxOfFutureAppointments}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex pt="10px" gap={2} justifyContent={'end'}>
              <Button variant="action" fontSize="md" fontWeight="500" px="24px" onClick={prevStep} py="5px">
                {t('Back')}
              </Button>
              <Button
                variant="brand"
                fontSize="md"
                type="submit"
                fontWeight="500"
                // isLoading={isLoadingUpdateBusiness}
                isDisabled={disableCreateButton(errors)}
                px="24px"
                py="5px"
              >
                {isUpdate ? t('Update') : t('Create')}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
}
