import { DateSelectArg } from '@fullcalendar/core';
import { DateMarker } from '@fullcalendar/core/internal';
import { startCase } from 'lodash';
import { BsPerson } from 'react-icons/bs';
import { MdDesignServices } from 'react-icons/md';
import validator from 'validator';
import { ARRAY_TIMES, DAYS_OF_WEEK, PASTEL_COLORS, UUID_REGEX, VIBRANT_COLORS } from './consts';
import { CreateTypeEnum } from './enums';
import {
  AppointmentEntity,
  EmployeeDaysOfWeekType,
  EmployeeEntityType,
  PlanEntityType,
  RoleType,
  TreatmentEntityType,
  TreatmentSelectType,
} from './types/app.types';

export function validateEmail(value: string, t: any) {
  let error;
  if (!validator.isEmail(value)) {
    error = t('Email must be a valid email address');
  }
  return error;
}

export const filterRoutesByPlan = (plan: PlanEntityType, routes: any[]) => {
  return routes.filter((route) => route.plans.some((routePlan: string) => routePlan === plan.name || routePlan === '*'));
};

export const filterRoutesByRole = (role: RoleType, routes: any[]) => {
  return routes.filter((route) => route.roles.some((routePlan: string) => routePlan === role.name || routePlan === '*'));
};

export const canCreateBasedOnPlan = (plan: PlanEntityType, createType: CreateTypeEnum, quantity: number) => {
  switch (createType) {
    case CreateTypeEnum.USER:
      return quantity < plan.info.users;
    case CreateTypeEnum.TREATMENT:
      return quantity < plan.info.treatments;
    case CreateTypeEnum.EMPLOYEE:
      return quantity < plan.info.employees;
    default:
      return false;
  }
};

export function validateDescription(value: string, t: any) {
  let error;
  if (value && value.length > 100) {
    error = t('Description must be at least 100 characters maximum');
  }
  return error;
}

export function validatePassword(value: string, t: any) {
  let error;
  if (value.length < 8) {
    error = t('Password must be 8 at least');
  }
  return error;
}

export function validateConfirmPassword(pass: string, confirmPass: string, t: any) {
  let error;
  if (pass !== confirmPass) {
    error = t('Passwords Does not match');
  }
  return error;
}

export function isValidUUID(uuid: string) {
  return UUID_REGEX.test(uuid);
}

export const serializeBusinessHours = (businessHours: any[]) => {
  return businessHours.map((businessDay) => {
    let businessCloseTime = '';
    const splitBySemicolons = businessDay.closeTime.split(':');
    if (splitBySemicolons[1] === '00') {
      businessCloseTime = `${splitBySemicolons[0]}:30`;
    } else {
      businessCloseTime = `${+splitBySemicolons[0] + 1}:00`;
    }
    return {
      daysOfWeek: [DAYS_OF_WEEK.indexOf(businessDay.dayOfWeek)], // Monday, Tuesday, Wednesday
      startTime: businessDay.openTime, // 8am
      endTime: businessCloseTime, // 6pm
    };
  });
};

export const stringToColor = (input: string, useVibrantColor = false): string => {
  const colors = useVibrantColor ? VIBRANT_COLORS : PASTEL_COLORS;

  let hash = 0;
  if (input.length === 0) return colors[hash];
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  hash = ((hash % colors.length) + colors.length) % colors.length;
  return colors[hash];
};

export function serializeCustomers(customers: any[]) {
  return customers.map((customer) => {
    const label = customer.firstName ? startCase(`${customer.firstName} ${customer.lastName}`) : customer.freeText;
    return {
      ...customer,
      value: customer.id ? customer.id : customer.freeText,
      label: label,
      Icon: BsPerson,
      iconColor: 'brand.900',
    };
  });
}

export const getDayName = (date: Date): string => {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayIndex = date.getDay();
  return daysOfWeek[dayIndex];
};

export function serializeEmployees(employees: EmployeeEntityType[]) {
  return employees.map((employee) => {
    return {
      ...employee,
      value: employee.id,
      label: startCase(`${employee.firstName} ${employee.lastName}`),
      Icon: BsPerson,
      iconColor: 'brand.900',
    };
  });
}

export function serializeTreatments(treatments: TreatmentEntityType[]): TreatmentSelectType[] {
  return treatments.map((treatment) => {
    return {
      ...treatment,
      value: treatment.id,
      label: startCase(treatment.name),
      Icon: MdDesignServices,
      iconColor: 'brand.900',
    };
  });
}

export function getCommonEmployeesFromTreatments(chosenTreatments: TreatmentSelectType[]): EmployeeEntityType[] {
  if (chosenTreatments.length === 0) return [];

  const minEmployeeTreatment = chosenTreatments.reduce((minTreatment, currentTreatment) => {
    return currentTreatment.employees.length < minTreatment.employees.length ? currentTreatment : minTreatment;
  });
  const allOtherTreatments = chosenTreatments.filter((treat) => treat.id !== minEmployeeTreatment.id);
  if (allOtherTreatments.length === 0) return minEmployeeTreatment.employees;

  const commonEmployees: EmployeeEntityType[] = [];
  minEmployeeTreatment.employees.forEach((minEmployee) => {
    // Check if this employee exists in all other treatments' employees
    const isCommon = allOtherTreatments.every((treatment) => treatment.employees.some((employee) => employee.id === minEmployee.id));

    // If the employee is common, add to commonEmployees array
    if (isCommon) {
      commonEmployees.push(minEmployee);
    }
  });

  return commonEmployees;
}

export function throwError(errorMsg: string): never {
  throw new Error(errorMsg);
}

export function addMinutesToTime(timeString: string, minutesToAdd: number): string {
  // Parse the input time string
  const [hours, minutes, seconds] = timeString.split(':').map(Number);

  // Convert hours and minutes to total minutes
  const totalMinutes = hours * 60 + minutes;

  // Add the specified minutes
  const newTotalMinutes = totalMinutes + minutesToAdd;

  // Calculate the new hours and minutes
  const newHours = Math.floor(newTotalMinutes / 60);
  const newMinutes = newTotalMinutes % 60;

  // Format the new time string
  let formattedTime = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
  if (seconds) {
    formattedTime = `${formattedTime}:${String(seconds).padStart(2, '0')}`;
  }

  return formattedTime;
}

export const setZoomHeight = (zoomValue: number) => {
  return `${20 + (zoomValue / 100) * 40}px`;
};

export const displayTimeFromISOToString = (event: DateMarker): string => {
  const time = event.toISOString();
  return time.split('T')[1].substring(0, 5);
};

export function toLocalISOString(date: Date) {
  const pad = (num: number) => (num < 10 ? '0' + num : num);

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are 0-indexed
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

export const getPastMonths = (amount = 6): string[] => {
  const date = new Date();
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const result = [];
  for (let i = 0; i < amount; i++) {
    const monthIndex = (date.getMonth() - i + 12) % 12;
    result.unshift(months[monthIndex]);
  }

  return result;
};

export const buildChakraColorFromString = (color: string) => {
  return `var(--chakra-colors-${color.split('.')[0]}-${color.split('.')[1]})`;
};

export const setUpTreatmentTime = (minOpenTime: string, createAppointmentDatesData?: DateSelectArg, appointmentData?: AppointmentEntity) => {
  if (createAppointmentDatesData && String(createAppointmentDatesData.start.getHours()) !== '0') {
    return `${String(createAppointmentDatesData.start.getHours()).padStart(2, '0')}:${String(createAppointmentDatesData.start.getMinutes()).padStart(
      2,
      '0',
    )}`;
  }
  if (appointmentData) {
    return appointmentData.startOfAppointment.substr(0, 5);
  }
  return minOpenTime;
};

export const formatDateYYYY_MM_DD = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const getMinTimeAndMaxTime = (businessHoursSerialized: any[]) => {
  let minTime = 24 * 60; // Set initial minTime to maximum minutes in a day
  let maxTime = 0; // Set initial maxTime to minimum minutes in a day

  for (const businessDay of businessHoursSerialized) {
    const { startTime, endTime } = businessDay;

    // Convert start and end times to minutes
    const startTimeMinutes = getTimeInMinutes(startTime);
    const endTimeMinutes = getTimeInMinutes(endTime);

    // Update minTime if necessary
    if (startTimeMinutes < minTime) {
      minTime = startTimeMinutes;
    }

    // Update maxTime if necessary
    if (endTimeMinutes > maxTime) {
      maxTime = endTimeMinutes;
    }
  }

  // Convert minTime and maxTime back to string format
  const formattedMinTime = formatTimeFromMinutes(minTime);
  const formattedMaxTime = formatTimeFromMinutes(maxTime);

  return { minOpenTime: formattedMinTime, maxCloseTime: formattedMaxTime };
};

export const getTimeInMinutes = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

export function isDateTimeNotSmallerThan(date: Date, timeString: string) {
  // Extract hours and minutes from the time string
  const [timeHours, timeMinutes] = timeString.split(':').map(Number);

  // Extract hours and minutes from the date object
  const dateHours = date.getHours();
  const dateMinutes = date.getMinutes();

  // Compare hours and minutes
  if (dateHours < timeHours) {
    return false;
  } else if (dateHours === timeHours) {
    return dateMinutes >= timeMinutes;
  } else {
    return true;
  }
}

export const areEmployeeWorkingHoursDifferent = (employeeWorkingDays: EmployeeDaysOfWeekType, employeeWorkingHours: EmployeeDaysOfWeekType) => {
  // Function to normalize the data for comparison
  const normalizeData = (data: EmployeeDaysOfWeekType) =>
    data
      .map(({ dayOfWeek, followsBusinessHours, openTime, closeTime }) => ({
        dayOfWeek,
        followsBusinessHours,
        openTime,
        closeTime,
      }))
      .sort((a, b) => a.dayOfWeek.localeCompare(b.dayOfWeek)); // Sort to ensure order doesn't affect comparison

  // Normalize both arrays
  const normalizedDays = normalizeData(employeeWorkingDays);
  const normalizedHours = normalizeData(employeeWorkingHours);

  // Convert to JSON strings and compare
  return JSON.stringify(normalizedDays) !== JSON.stringify(normalizedHours);
};

export const filterTimes = (minTime: string, maxTime: string) => {
  return ARRAY_TIMES.filter((time) => {
    const minutes = getTimeInMinutes(time);
    return minutes <= getTimeInMinutes(maxTime) && minutes >= getTimeInMinutes(minTime);
  });
};

export const getInitDate = (appointmentData: AppointmentEntity, createAppointmentDatesData: DateSelectArg) => {
  if (appointmentData) {
    return new Date(`${appointmentData.dateOfAppointment}T${appointmentData.startOfAppointment}`);
  }
  if (createAppointmentDatesData) {
    return createAppointmentDatesData.start;
  }
  return new Date();
};

export const sumTreatmentsTime = (treatments: TreatmentEntityType[]) => {
  return treatments.map((treat) => +treat.timeOfTreatment).reduce((sum, time) => sum + time, 0);
};

export const displayDateReverse = (date: string) => {
  const parts = date.split('-');
  if (parts.length === 3) {
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  }
  return '';
};

export function getMinutesDifference(timeA: string, timeB: string) {
  const minutesA = getTimeInMinutes(timeA);
  const minutesB = getTimeInMinutes(timeB);

  // Calculate the absolute difference between the two times
  const difference = Math.abs(minutesB - minutesA);

  return difference;
}

export function timeSince(dateString: string): string {
  const now = new Date();
  const pastDate = new Date(dateString);
  const diffMs = now.getTime() - pastDate.getTime();

  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

  let result = '';
  if (diffDays > 0) {
    result += `${diffDays}d `;
  }
  if (diffHours > 0 || diffDays > 0) {
    result += `${diffHours}h `;
  }
  result += `${diffMinutes}min`;

  return result.trim();
}

// Function to convert minutes to time string
const formatTimeFromMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
};
