import FullCalendar from '@fullcalendar/react';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

import listPlugin from '@fullcalendar/list';
import Event from './Event';

import { useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';
import { DateSelectArg, DatesSetArg, EventChangeArg, EventClickArg } from '@fullcalendar/core';
import { EventImpl, VerboseFormattingArg } from '@fullcalendar/core/internal';
import AppointmentDrawer from 'components/drawers/AppointmentDrawer';
import AppointmentTypeModal from 'components/modals/AppointmentTypeModal';
import CreateOrEditAppointmentModal from 'components/modals/CreateOrEditAppointmentModal';
import EditAppointmentModal from 'components/modals/EditAppointmentModal';
import SliderThumbWithTooltip from 'components/Slider';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { APPOINTMENT_PARAM, HIDDEN_WEEKEND_DAYS } from 'shared/consts';
import { filterTimes, getInitDate, getMinTimeAndMaxTime, isDateTimeNotSmallerThan } from 'shared/helpersFunctions';
import { useEmployees } from 'shared/hooks/useEmployees';
import { useHolidays } from 'shared/hooks/useHolidays';
import { AppointmentEntity, EmployeeSelectType } from 'shared/types/app.types';
import { getURLParam, resetURLParam, setURLParam } from 'shared/urlFunctions';
import { EmployeesInput } from './EmployeesInput';
import { DatesSelection } from './MainCalendar';
import { NewAppointmentIcon } from './NewAppointmentIcon';
import { RefreshAppointmentIcon } from './RefreshAppointmentIcon';
import { CalendarContainer } from './StyledCalendarContainer';
import { ToggleWeekendView } from './ToggleWeekendView';

interface ICalendarProps {
  appointmentsOfDate: any[];
  dateSelection: DatesSelection;
  businessHoursSerialized: any[];
  setDateFromMainCalendar: (dates: DatesSetArg) => void;
}

export default function Calendar({ appointmentsOfDate, dateSelection, businessHoursSerialized, setDateFromMainCalendar, ...rest }: ICalendarProps) {
  const [weekendsVisible, setWeekendsVisible] = useState<boolean>(false);
  const calendarColor = useColorModeValue('brand.400', 'brand.600');
  const dateTextColor = useColorModeValue('brand.300', 'white');
  const calendarNavigationBgColor = useColorModeValue('rgba(48, 0, 125, 0.3)', 'rgba(8, 100, 235, 0.2)');
  const navigate = useNavigate();
  const location = useLocation();
  const appointment = location.state as AppointmentEntity | undefined;
  const buttonActiveBgColor = useColorModeValue('#0005008f', '#312aaa5c');
  const [editDatesData, setEditDatesData] = useState<EventChangeArg>();
  const [appointmentParam, setAppointmentParam] = useState<string | undefined>(getURLParam(APPOINTMENT_PARAM));
  const [removeEventCallback, setRemoveEventCallback] = useState<EventImpl>();
  const { userData } = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const [appointmentData, setAppointmentData] = useState<AppointmentEntity>(appointment);
  const { maxCloseTime, minOpenTime } = getMinTimeAndMaxTime(businessHoursSerialized);
  const businessWorkingDaysWeekNumbers = businessHoursSerialized.map((bTime) => bTime.daysOfWeek[0]);
  const filteredTimesByBusinessHours = filterTimes(minOpenTime, maxCloseTime);
  const toast = useToast();
  const { employees, isLoadingEmployees } = useEmployees();
  const [createAppointmentDatesData, setCreateAppointmentDatesData] = useState<DateSelectArg>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { holidays } = useHolidays();
  const [chosenEmployees, setChosenEmployees] = useState<EmployeeSelectType[]>([]);
  const [zoomValue, setZoomValue] = useState<number>(0);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const appointmentId = params.get(APPOINTMENT_PARAM);
    setAppointmentParam(appointmentId);
  }, [location]);

  const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
  const { isOpen: isMidOpen, onOpen: onMidOpen, onClose: onMidClose } = useDisclosure();
  const { isOpen: isAbsenceOpen, onOpen: onAbsenceOpen, onClose: onAbsenceClose } = useDisclosure();
  const {
    isOpen: isEditOrDeleteDrawerOpen,
    onOpen: onEditOrDeleteDrawerOpen,
    onClose: onEditOrDeleteDrawerClose,
  } = useDisclosure({
    isOpen: location.state ?? (appointmentData && appointmentParam),
  });

  const handleWeekendsToggle = () => {
    setWeekendsVisible(() => !weekendsVisible);
  };

  const closeEditModalAndRevertChanges = () => {
    setEditDatesData(null);
    onClose();
    editDatesData.revert();
  };

  const closeEditModal = () => {
    resetURLParam(APPOINTMENT_PARAM);
    setAppointmentParam(undefined);
    setEditDatesData(null);
    onClose();
  };

  const closeEditOrDeleteModal = () => {
    resetURLParam(APPOINTMENT_PARAM);
    setAppointmentParam(undefined);
    setAppointmentData(() => null);
    onEditOrDeleteDrawerClose();
  };

  const closeCreateModal = () => {
    onCreateClose();
    setCreateAppointmentDatesData(null);
    setAppointmentData(() => null);
  };

  const openEditAppointment = (data: EventChangeArg) => {
    if (!businessWorkingDaysWeekNumbers.includes(data.event._instance.range.start.getDay())) {
      toast({
        title: t('Error'),
        description: t('Business is not open on that day'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      data.revert();
      return;
    }
    setEditDatesData(() => data);
    onOpen();
  };

  const openCreateAppointment = (data: DateSelectArg) => {
    setAppointmentData(null);
    const currentBusinessData = businessHoursSerialized.filter((date) => date.daysOfWeek[0] === data.start.getDay());
    const startDay = data.start.getDay();
    if (
      businessWorkingDaysWeekNumbers.includes(startDay) &&
      (isDateTimeNotSmallerThan(data.start, currentBusinessData[0].startTime) || data.view.type === 'dayGridMonth')
    ) {
      setCreateAppointmentDatesData(data);
      onMidOpen();
    } else {
      toast({
        title: t('Error'),
        description: t('Business is not open on that day'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const openEditOrDeleteAppointment = (data: EventClickArg) => {
    if (!data.event._def.allDay) {
      setURLParam(APPOINTMENT_PARAM, data.event.id, navigate);
      setAppointmentParam(data.event.id);
      setAppointmentData({
        id: data.event._def.publicId,
        ...data.event._def.extendedProps,
      } as AppointmentEntity);
      setRemoveEventCallback(() => data.event);
      onEditOrDeleteDrawerOpen();
    }
  };

  const onEditClick = (appointmentData: AppointmentEntity) => {
    if (appointmentData.absence) {
      onAbsenceOpen();
    } else {
      resetURLParam(APPOINTMENT_PARAM);
      setAppointmentParam(undefined);
      onEditOrDeleteDrawerClose();
      onCreateOpen();
    }
  };

  const calendarRef = useRef(null);

  useEffect(() => {
    if (location.state && location.state.chosenDates) {
      const { view, date } = location.state.chosenDates;
      if (calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.changeView(view, new Date(date));
      }
    }
  }, [location.state]);

  const closeCreateOrEditModal = () => {
    onEditOrDeleteDrawerClose();
    setAppointmentData(null);
  };

  const chosenEmployeesIds = chosenEmployees.map((employee) => employee.id);

  const filteredAppointments = (appointmentsOfDate ?? []).filter((appointment) => {
    if (chosenEmployeesIds.length === 0) {
      return true;
    }
    return chosenEmployeesIds.includes(appointment.employeeId.id);
  });
  const allEvents = [...filteredAppointments, ...holidays];
  const displayingWeekendToggle = dateSelection.view === 'dayGridMonth' || dateSelection.view === 'timeGridWeek';
  const displayingZoom = dateSelection.view === 'timeGridWeek' || dateSelection.view === 'timeGridDay';
  return (
    <>
      <CalendarContainer
        bgColor={calendarColor}
        zoomValue={zoomValue}
        dateTextColor={dateTextColor}
        buttonActiveBgColor={buttonActiveBgColor}
        calendarNavigationBgColor={calendarNavigationBgColor}
      >
        <FullCalendar
          ref={calendarRef}
          {...rest}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            // bootstrap5Plugin,
            listPlugin,
          ]}
          editable
          allDaySlot={false}
          initialDate={dateSelection.date}
          initialView={dateSelection.view}
          nowIndicator
          navLinks
          selectable
          selectMirror
          expandRows
          contentHeight={'75vh'}
          slotEventOverlap={false}
          dayMaxEvents
          stickyHeaderDates
          slotLabelFormat={(arg: VerboseFormattingArg) => {
            const date = arg.date.marker;
            if (arg.date.minute === 0) {
              // Full hour format (08:00, 09:00)
              return date.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
                timeZone: 'UTC',
              });
            } else {
              // Only minutes (15, 30, 45)
              return `${date.getUTCMinutes().toString().padStart(2, '0')}:`;
            }
          }}
          eventTimeFormat={{
            // hour: "2-digit",
            // minute: "2-digit",
            omitZeroMinute: false,
            hour12: false, // Ensure 24-hour format
          }}
          locale="he"
          firstDay={0}
          // themeSystem="bootstrap5"
          direction="rtl"
          buttonText={{
            today: t('Today'),
            month: t('Month'),
            week: t('Week'),
            day: t('Day'),
            list: t('List'),
          }}
          allDayText={t('')}
          // validRange
          hiddenDays={!weekendsVisible ? HIDDEN_WEEKEND_DAYS : []}
          events={allEvents} // alternatively, use the `events` setting to fetch from a feed
          eventContent={(e: any) => <Event eventContent={e} />} // custom render function
          datesSet={(dates: DatesSetArg) => setDateFromMainCalendar(dates)}
          slotDuration="00:15:00" // Set slot duration to 15 minutes
          slotLabelInterval="00:15:00"
          slotMinTime={minOpenTime} // Set minimum time to start at midnight
          slotMaxTime={maxCloseTime} // Set maximum time to end at 11:59:59 PM
          // eventsSet={handleEvents}
          dayHeaders
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
          }}
          titleFormat={{ day: '2-digit', month: '2-digit' }}
          businessHours={businessHoursSerialized ?? []}
          // dropAccept={(data) => {
          //   console.log(data);
          //   return false;
          // }}
          // TODO: fix edit
          eventChange={(data: EventChangeArg) => {
            openEditAppointment(data);
          }}
          // eventsSet={(data: EventApi[]) => console.log(data)}
          // eventAdd={(data: EventAddArg) => console.log(data)}
          eventClick={(data: EventClickArg) => openEditOrDeleteAppointment(data)}
          select={(data: DateSelectArg) => openCreateAppointment(data)}

          // called after events are initialized/added/changed/removed
          /* you can update a remote database when these fire:
        eventRemove={function(){}}
        */
        />
        {displayingWeekendToggle && (
          <ToggleWeekendView
            moreThanOneEmployee={employees.length > 1}
            handleWeekendsToggle={handleWeekendsToggle}
            weekendsVisible={weekendsVisible}
          />
        )}
        <NewAppointmentIcon onCreateOpen={onMidOpen} />
        {employees.length > 1 && (
          <EmployeesInput
            isLoadingEmployees={isLoadingEmployees}
            employees={employees}
            setChosenEmployees={setChosenEmployees}
            chosenEmployees={chosenEmployees}
          />
        )}
        <RefreshAppointmentIcon />
        {displayingZoom && <SliderThumbWithTooltip zoomValue={zoomValue} setZoomValue={setZoomValue} />}
        {appointmentData && appointmentParam && (
          <AppointmentDrawer
            onEditClick={onEditClick}
            onEditOrDeleteDrawerClose={onEditOrDeleteDrawerClose}
            closeEditOrDeleteModal={closeEditOrDeleteModal}
            appointmentData={appointmentData}
            isOpen={isEditOrDeleteDrawerOpen}
            removeEventCallback={removeEventCallback}
          />
        )}
        {editDatesData && (
          <EditAppointmentModal
            filteredTimesByBusinessHours={filteredTimesByBusinessHours}
            closeEditModal={closeEditModal}
            editDatesData={editDatesData}
            minOpenTime={minOpenTime}
            closeEditModalAndRevertChanges={closeEditModalAndRevertChanges}
            isOpen={isOpen}
          />
        )}
        {isCreateOpen && (
          <CreateOrEditAppointmentModal
            createAppointmentDatesData={createAppointmentDatesData}
            appointmentData={appointmentData}
            businessWorkingDaysWeekNumbers={businessWorkingDaysWeekNumbers}
            onEditOrDeleteDrawerClose={closeCreateOrEditModal}
            closeModal={closeCreateModal}
            isOpen={isCreateOpen}
            minOpenTime={minOpenTime}
          />
        )}
        {(isMidOpen || isAbsenceOpen) && (
          <AppointmentTypeModal
            isOpen={isMidOpen}
            isAbsenceOpen={isAbsenceOpen}
            initialDate={getInitDate(appointmentData, createAppointmentDatesData)}
            businessWorkingDaysWeekNumbers={businessWorkingDaysWeekNumbers}
            appointmentData={appointmentData}
            onAbsenceClose={onAbsenceClose}
            onAbsenceOpen={onAbsenceOpen}
            closeModal={onMidClose}
            maxCloseTime={maxCloseTime}
            minOpenTime={minOpenTime}
            onCreateOpen={onCreateOpen}
          />
        )}
      </CalendarContainer>
    </>
  );
}
