import { Box, Flex, Heading, Icon, Switch, Text, Tooltip, VStack } from '@chakra-ui/react';
import TimeAndDayEmployee from 'components/inputs/TimeAndDayEmployee';
import { useTranslation } from 'react-i18next';
import { MdHelpOutline } from 'react-icons/md';
import { DaysOfBusinessType, DaysOfWeekType, EmployeeDaysOfWeekType } from 'shared/types/app.types';

interface IEmployeeWorkingHoursProps {
  daysOfEmployee?: EmployeeDaysOfWeekType;
  businessHours: DaysOfBusinessType[];
  isReadOnly: boolean;
  handleChangeOnDayToggle?: (dayOfWeek: DaysOfWeekType) => void;
  handleFollowBusinessCheck?: (dayOfWeek: DaysOfWeekType) => void;
  onChangeTime?: (time: string, dayOfWeek: DaysOfWeekType, openOrClose: 'O' | 'C') => void;
}

export const EmployeeWorkingHours = ({
  handleChangeOnDayToggle,
  handleFollowBusinessCheck,
  onChangeTime,
  businessHours,
  isReadOnly,
  daysOfEmployee,
}: IEmployeeWorkingHoursProps) => {
  const { t } = useTranslation();
  return (
    <>
      {businessHours.map((day, index) => {
        const isOpen = daysOfEmployee.map((dayItem) => dayItem.dayOfWeek).includes(day.dayOfWeek);
        const employeeDay = daysOfEmployee.find((dayItem) => dayItem.dayOfWeek === day.dayOfWeek);
        const currentDayData = daysOfEmployee.find((dayItem) => dayItem.dayOfWeek === day.dayOfWeek);
        return (
          <Box key={day.dayOfWeek} transition="1.2s linear">
            <Flex alignItems={'center'} gap={1} width="100%" justifyContent={'space-between'}>
              <Switch
                colorScheme={'green'}
                isChecked={isOpen}
                isReadOnly={isReadOnly}
                onChange={() => handleChangeOnDayToggle(day.dayOfWeek)}
                size="md"
                sx={{ direction: 'ltr' }}
              />
              <Heading as="h2" size="md" mx={2}>
                {t(day.dayOfWeek)}
              </Heading>
              <Box width="100%">
                {index === 0 && (
                  <Flex justifyContent={'space-between'}>
                    <Flex justifyContent={'space-around'} w="100%">
                      <Heading as="h2" size="md">
                        {t('End')}
                      </Heading>
                      <Heading as="h2" size="md">
                        {t('Start')}
                      </Heading>
                    </Flex>
                    <Flex>
                      <Tooltip
                        hasArrow
                        label={
                          <VStack align="center" spacing={1}>
                            <Text fontWeight="bold">{t('followBusinessHours.title')}</Text>
                            <Text fontSize="md">{t('followBusinessHours.description')}</Text>
                          </VStack>
                        }
                        bg="gray.700"
                        color="white"
                        p={3}
                        borderRadius="md"
                        placement="top"
                      >
                        <Box cursor="pointer">
                          <Icon as={MdHelpOutline} display="flex" width="22px" height="22px" color="blue.500" />
                        </Box>
                      </Tooltip>
                    </Flex>
                  </Flex>
                )}
                <TimeAndDayEmployee
                  day={day.dayOfWeek}
                  onChangeTime={onChangeTime}
                  onCheckBoxChange={handleFollowBusinessCheck}
                  shouldFollowBusiness={employeeDay?.followsBusinessHours ?? false}
                  minStartTime={day.openTime}
                  maxFinishTime={day.closeTime}
                  openTime={currentDayData?.openTime}
                  closeTime={currentDayData?.closeTime}
                  isOpen={isOpen}
                  isReadOnly={isReadOnly}
                />
              </Box>
            </Flex>
          </Box>
        );
      })}
    </>
  );
};
