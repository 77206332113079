import { Avatar, Box, Flex, Icon, Text } from '@chakra-ui/react';
import { EventContentArg } from '@fullcalendar/core';
import { useTranslation } from 'react-i18next';
import { BsPatchExclamationFill } from 'react-icons/bs';
import { MdTimer } from 'react-icons/md';

import { sumTreatmentsTime } from 'shared/helpersFunctions';
import { BusinessEntityType, EmployeeEntityType, TreatmentEntityType } from 'shared/types/app.types';

interface ITimeGridDayViewProps {
  businessData: BusinessEntityType;
  treatments: TreatmentEntityType[];
  freeText: string;
  absence: boolean;
  employee: EmployeeEntityType;
  eventContent: EventContentArg;
  customer?: any;
}

export default function TimeGridDayView({ businessData, customer, treatments, freeText, absence, employee, eventContent }: ITimeGridDayViewProps) {
  const { comment, startOfAppointment, endOfAppointment } = eventContent.event._def.extendedProps;
  const { t } = useTranslation();
  if (absence) {
    return (
      <Flex
        borderRadius="10px"
        px="5px"
        py="3px"
        gap="5px"
        // bgColor={"red"}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        height="100%"
      >
        <Flex position="absolute" top="0" left="0" justifyContent={'end'} w="100%">
          {comment && <Icon as={BsPatchExclamationFill} color={'red.600'} width="30px" height="30px" />}
        </Flex>
        <Flex justifyContent={'center'} alignItems={'center'} flexDirection={'column'} gap={1}>
          <Flex gap={2}>{t('Absence')}</Flex>
          <Text isTruncated fontWeight="600" m="0px">
            {`${employee.firstName} ${employee.lastName}`}
          </Text>
        </Flex>
      </Flex>
    );
  }

  const customerNameOrFreeText = customer ? `${customer.firstName} ${customer.lastName}` : freeText;

  const timeOfTreatment = sumTreatmentsTime(treatments);

  if (timeOfTreatment <= 20) {
    return (
      <Flex borderRadius="10px" position="relative" justifyContent="space-between">
        <Flex justifyContent="space-between" flexDirection="row">
          <Flex px={1} gap={1}>
            <Avatar h="18px" w="18px" src={customer?.photo?.path} />
            <Text isTruncated maxW="150px">
              {customerNameOrFreeText}
            </Text>
          </Flex>
        </Flex>
        <Flex alignItems="start" gap={0.5} justifyContent="end" flexDirection="row">
          <Icon as={MdTimer} w="18px" h="18px" />
          <Text isTruncated fontSize="sm">{`${startOfAppointment.substr(0, 5)} - ${endOfAppointment.substr(0, 5)}`}</Text>
          {comment && <Icon as={BsPatchExclamationFill} color="red.600" w="18px" h="18px" />}
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex borderRadius="10px" py="2px" px={'2px'} position="relative" justifyContent="space-between">
      <Flex justifyContent="space-between" flexDirection={'row'}>
        <Avatar h="35px" w="35px" src={customer?.photo?.path} />
        <Flex flexDirection={'column'} px={1}>
          <Text isTruncated>{`${customerNameOrFreeText}`}</Text>
          <Text isTruncated fontSize="xs" color="secondary.500">
            {treatments.map((treat) => treat.name).join(', ')}
          </Text>
        </Flex>
      </Flex>
      <Box position="absolute" top={0} left={0} width="100%" pt={0.5} pl={1} textAlign="left">
        <Flex justifyContent="flex-end" alignItems={'center'}>
          <Text isTruncated fontSize="sm">{`${startOfAppointment.substr(0, 5)} - ${endOfAppointment.substr(0, 5)}`}</Text>
          {comment && <Icon as={BsPatchExclamationFill} color={'red.600'} width="20px" height="30px" />}
        </Flex>
        <Flex alignItems="center" gap={0.5} justifyContent="flex-end">
          <Icon as={MdTimer} w="18px" h="18px" />
          <Text isTruncated fontSize="sm">{`${timeOfTreatment} ${t('minutes')}`}</Text>
        </Flex>
      </Box>
    </Flex>
  );
}
