import {
  Box,
  Button,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { AiOutlineEdit } from 'react-icons/ai';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getBusinessImages, updateBusinessesImages } from 'services/api/business';

const ImageGridWithEdit = ({ banner, businessId }: { businessId: string; banner: string }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const bgColor = useColorModeValue('white', 'brand.500');
  const [selectedImage, setSelectedImage] = useState<number>(null);
  const [file, setFile] = useState(null);
  const queryClient = useQueryClient();

  const handleDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  };

  const { data: businessImages, isLoading: isLoadingBusinessHours } = useQuery<Record<number, string>>(
    ['businessImages'],
    () => getBusinessImages(businessId),
    {
      select: (imagesArray: any) => {
        const resultsToReturn: Record<number, string> = {
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
        };

        // Loop through the array of image URLs
        imagesArray.forEach((imageUrl: any) => {
          // Extract the last character from the URL (assuming it's the number)
          const lastCharacter = imageUrl[imageUrl.length - 1];
          const key = parseInt(lastCharacter, 10); // Convert to a number

          // If the key is between 1 and 6, update the dictionary
          if (key >= 1 && key <= 6) {
            resultsToReturn[key] = imageUrl;
          }
        });

        return resultsToReturn;
      },
    },
  );

  const { mutate: uploadImage, isLoading } = useMutation(updateBusinessesImages, {
    onSuccess: () => {
      setIsModalOpen(false);
      setFile(null);
      queryClient.invalidateQueries('businessImages');
      setIsModalOpen(false);
    },
    onError: () => {
      alert('Failed to upload image. Please try again.');
    },
  });

  const handleUpload = () => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      uploadImage({ ImagePayload: formData, businessId, imageNumber: selectedImage });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
    accept: 'image/*',
  });

  return (
    <>
      <SimpleGrid columns={{ base: 2, md: 3 }} spacing={{ base: 4, lg: 8 }} sx={{ py: { base: 4, lg: 8 }, px: { base: 0, lg: 2 } }}>
        {[1, 2, 3, 4, 5, 6].map((n) => (
          <Box
            key={n}
            position="relative"
            width="100%" // Ensures the box takes full width of the grid column
            paddingTop="100%" // Ensures the box maintains a square aspect ratio
          >
            {/* Centered and responsive square image */}
            <Box position="absolute" top="0" left="0" width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
              <Image
                src={businessImages && !!businessImages[n] ? businessImages[n] : banner}
                alt={`img-${n}`}
                objectFit="cover"
                borderRadius="md"
                width="100%"
                height="100%"
              />
            </Box>

            {/* Edit icon positioned at the top-right corner */}
            <Icon
              as={AiOutlineEdit}
              boxSize={{ base: 8, md: 12 }}
              position="absolute"
              top={2}
              right={2}
              bg={bgColor}
              cursor="pointer"
              borderRadius="full"
              p={1}
              onClick={() => {
                setSelectedImage(n);
                setIsModalOpen(true);
              }}
            />
          </Box>
        ))}
      </SimpleGrid>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader sx={{ textAlign: 'center' }}>Edit Image {selectedImage}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              {...getRootProps()}
              border="2px dashed"
              borderColor="gray.300"
              borderRadius="md"
              p={6}
              textAlign="center"
              cursor="pointer"
              bg={bgColor}
            >
              <input {...getInputProps()} />
              {file ? <p>{file.name}</p> : <p>Drag & drop an image here, or click to select one</p>}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="action" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button variant={'brand'} mr={3} onClick={handleUpload} isLoading={isLoading} isDisabled={!file}>
              Upload
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImageGridWithEdit;
