import { Box, Flex, Heading, Switch } from '@chakra-ui/react';
import TimeAndDay from 'components/inputs/TimeAndDay';
import { useTranslation } from 'react-i18next';
import { DAYS_OF_WEEK } from 'shared/consts';
import { BusinessDaysOfWeekType, DaysOfWeekType } from 'shared/types/app.types';

interface IOpeningHoursProps {
  daysOfBusiness?: BusinessDaysOfWeekType;
  isReadOnly: boolean;
  handleChangeOnDayToggle?: (dayOfWeek: DaysOfWeekType) => void;
  onChangeTime?: (time: string, dayOfWeek: DaysOfWeekType, openOrClose: 'O' | 'C') => void;
}

export const OperatingHours = ({ handleChangeOnDayToggle, onChangeTime, isReadOnly, daysOfBusiness }: IOpeningHoursProps) => {
  const { t } = useTranslation();
  return (
    <>
      {DAYS_OF_WEEK.map((dayName) => {
        const isOpen = daysOfBusiness.map((dayItem) => dayItem.dayOfWeek).includes(dayName as DaysOfWeekType);
        const currentDayData = daysOfBusiness.find((dayItem) => dayItem.dayOfWeek === dayName);
        return (
          <Box key={dayName} transition="1.2s linear">
            <Flex alignItems={'center'} gap={1} width="100%" justifyContent={'space-between'}>
              <Switch
                colorScheme={'green'}
                isChecked={isOpen}
                isReadOnly={isReadOnly}
                onChange={() => handleChangeOnDayToggle(dayName as DaysOfWeekType)}
                size="md"
                sx={{ direction: 'ltr' }}
              />
              <Heading as="h2" size="md" mx={2}>
                {t(dayName)}
              </Heading>
              <Box width="100%">
                <TimeAndDay
                  day={dayName as DaysOfWeekType}
                  onChangeTime={onChangeTime}
                  openTime={currentDayData?.openTime}
                  closeTime={currentDayData?.closeTime}
                  isOpen={isOpen}
                  isReadOnly={isReadOnly}
                />
              </Box>
            </Flex>
          </Box>
        );
      })}
    </>
  );
};
